import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../components/dynamic-zone/dynamic-zone';
import Layout from '../../components/layout';
import React from 'react';

const ContactUsPage = (props) => {
    const components = props.data.cms.contactUsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <DynamicZone components={components} />
        </Layout>
    );
};

export default ContactUsPage;

export const query = graphql`
query ($locale: String!){
  cms {
    contactUsPageTemplate(locale: $locale){
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          backgroundImage {
            url
          }
          backgroundImageHeight
          contactForm {
            dropdownContent
            buttonLabel
            checkboxLabel
            dropdownLabel
            eMailAddressLabel
            formTitle
            messageLabel
            nameAndSurnameLabel
            privacyPolicyFile {
              url
            }
          }
          contentOffset
          mobileContentOffset
          primaryImage {
            url
          }
          headerSubtitle: subtitle
          textBoxCustomClasses
          title
        }
        ... on STRAPI_ComponentOurOfficesOurOffices {
          __typename
          topPusherHeight
          topPusherHeightMobile
          londonData {
            cityName
            image {
              url
            }
            officesData
          }
          sectionTitle {
            tailwindClasses
            description
            style
            title
            ytLink
          }
          warsawData {
            cityName
            officesData
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}

`;
